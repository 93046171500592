import React, { useState, useEffect } from 'react';
import { Tooltip } from 'react-tooltip';

const PercentageInput = ({ toolTipText, value, setValue, label, id, inputMax }) => {
  const [internalValue, setInternalValue] = useState('');

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const handleChange = (event) => {
    let newValue = event.target.value;

    // Allow empty field or valid percentage input
    if (newValue === '' || /^(?:\d{1,2}(?:\.\d{1,2})?|\d{1,2}(?:\.\d{0,2})?)$/.test(newValue)) {
      setInternalValue(newValue);
      setValue(newValue); // Update the state correctly
    }
  };

  const handleBlur = () => {
    // If the field is empty, set the value to '0'
    if (internalValue === '') {
      setInternalValue('0');
      setValue('0');
    }
  };

  return (
    <div className="inputGroup">
      <div className="label-container">
        <label className="form-label" htmlFor={id}>
          {label}
        </label>
        {toolTipText && (
          <img
            alt=""
            data-tooltip-id="my-tooltip"
            data-tooltip-html={toolTipText}
            className="tooltip-indicator"
            src="https://uploads-ssl.webflow.com/6443797b2dbb5540710c396a/6475d08ea6288220f6b44261_tooltip.svg"
          ></img>
        )}
        <Tooltip id="my-tooltip" />
      </div>
      <div className="input-wrapper percentage">
        <input
        inputMode='decimal'
          name={id}
          id={id}
          type="text"
          value={internalValue}
          onChange={handleChange}
          onBlur={handleBlur}
          maxLength={inputMax}
          className="number-input percentinput"
          placeholder="Enter Amount"
        />
        <span className="percenticon">%</span>
      </div>
    </div>
  );
};

export default PercentageInput;
