import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import ReactPlayer from 'react-player';

const VideoModal = ({ videoUrl, buttonText }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [videoIsPlaying, setVideoIsPlaying] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
    setVideoIsPlaying(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleVideoEnded = () => {
    setVideoIsPlaying(false);
  };

  const modalStyle = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(0, 0, 0, 0.7)',
      display:'flex',
      alignItems: 'center',
      zIndex:'999999'
    },
    content: {
      background: 'transparent',
      border: 'none',
      padding: 0,
      width: 'auto',
      height: 'auto',
      margin: 'auto',
      display:'flex',
      alignItems: 'center',
      justifyContent:'center'
    },
  };

  const closeButtonStyle = {
    position: 'absolute',
    top: '20px',
    right: '10px',
    color: 'white',
    cursor: 'pointer',
    fontSize: '60px',
    zIndex: 999999999,
  };


  return (
    <div>
      <button className="modalTriggerText" onClick={openModal}>{buttonText}</button>
      <ReactModal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Video Modal"
        style={modalStyle}
        shouldCloseOnOverlayClick={true}
      >
        <div style={closeButtonStyle} onClick={closeModal}>
          &times;
        </div>
        {modalIsOpen && (
          <div className='player-wrapper'>
            <ReactPlayer
            className='react-player'
              url={videoUrl}
              playing={videoIsPlaying}a
              controls
              width="100%"
              height="100%"
              onEnded={handleVideoEnded}
            />
          </div>
        )}
      </ReactModal>
    </div>
  );
};

export default VideoModal;
