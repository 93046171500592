import React, { useState, useEffect } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { RotatingLines } from "react-loader-spinner";
import "@splidejs/splide/css/core";
import { Link } from "react-router-dom";
import Result from "./Result";
import { useSearchParams } from "react-router-dom";
import VimeoPlayer from "./VimeoPlayer";
import LeadFormPopup from "./leadformpopup";

const Step5 = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [total, setTotal] = useState(props.total);
  const [investment, setInvestment] = useState(() => {
    const investmentParam = searchParams.get("investment");
    return investmentParam != null ? Number(investmentParam) : props.investment;
    // return investmentParam ?? props.investment
  });
  const [isLoading, setIsLoading] = useState(true);
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    const fetchDataStoreSlides = async () => {
      console.log("fetching slides...");
      const options = {
        method: "GET",
        headers: { accept: "application/json" },
      };

      try {
        //fetch the data
        const response = await fetch(
          "https://retirement-calculator-backend.onrender.com/",
          options
        );
        const data = await response.json();
        console.log(data);
        const items = data;
        console.log(items);

        //store the data in a new array
        const newSlides = items.map((item, i) => (
          <SplideSlide key={i}>
            <Result
              investment={investment}
              isAverill={item["highlighted-product"]}
              productTitle={item["product-title"]}
              productDisplayName={item["product-display-name"]}
              bestDate={item["best-date"]}
              bestRate={item["best-rate"]}
              averageDate={item["average-date"]}
              averageRate={item["average-rate"]}
              worstDate={item["worst-date"]}
              worstRate={item["worst-rate"]}
              imageUrl={item["client-satisfaction-image"]?.url}
              dollarAmountDecimal={item["dollar-amount-decimal"]}
            ></Result>
          </SplideSlide>
        ));

        // Update the state with the newSlides array
        setSlides(newSlides);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    fetchDataStoreSlides();

    const timer = setTimeout(() => {
      // Find the element with the class 'popupbtn'
      const button = document.querySelector(".popupbtn");

      // Check if the element exists and then click it
      if (button) {
        button.click();
      }
    }, 30000); // 30000 milliseconds = 30 seconds

    return () => clearTimeout(timer);
  }, [investment]);

  useEffect(() => {
    const timer = setTimeout(() => {
      // Select the element by ID for better specificity
      const lightbox = document.querySelector(".lightbox");

      // Check if the element exists and then click it
      if (lightbox) {
        lightbox.classList.add("displayblock");
      }
    }, 30000); // 30000 milliseconds = 30 seconds

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    console.log("mounting step 5");
    //scroll to top of page
    window.scrollTo(0, 0);
    function validateParam(value) {
      if (value == null) return false;
      if (isNaN(value)) return false;
      return true;
    }
    const investmentStateValid = validateParam(investment);
    const investmentParam = searchParams.get("investment");
    const investmentParamValid = validateParam(investmentParam);
    if (investmentStateValid && !investmentParamValid) {
      console.log("setting investment param");
      setSearchParams({ investment });
    }

    //get search params
    // let searchParams = new URLSearchParams(window.location.search);
    // const interestRate = searchParams.get("interestrate");
    // const investmentAmount = searchParams.get("investment");
    // let redirect = false;

    // //if the parameters are valid, set them as state
    // if (interestRate && !isNaN(interestRate)) {
    //   setTotal(Number(interestRate));
    // }

    // if (investmentAmount && !isNaN(investmentAmount)) {
    //   const
    //   console.log('change investment')
    //   setInvestment(Number(investmentAmount));
    // }
  }, [investment, searchParams, setSearchParams]);

  const formatCurrency = (number) => {
    // Convert the number to a string
    let numStr = String(number);

    // Split the string into whole number and decimal parts
    let parts = numStr.split(".");
    let wholeNumber = parts[0];

    // Insert commas every three digits in the whole number part
    let formattedNumber = "";
    for (let i = wholeNumber.length - 1, j = 1; i >= 0; i--, j++) {
      formattedNumber = wholeNumber.charAt(i) + formattedNumber;
      if (j % 3 === 0 && i > 0) {
        formattedNumber = "," + formattedNumber;
      }
    }

    return formattedNumber;
  };

  if (investment.isNaN || investment === 0) {
    return (
      <div>
        Error, please check your inputs and try again.
        <Link to={"/"}>Go back</Link>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div id="currentcontent" className="currentContent largepadding">
        <h2 className="spinner">Retrieving your results...</h2>
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="96"
          visible={true}
        />
        <p className="spinner-top">
          Artificial intelligence is finding the highest historical returns...
        </p>
      </div>
    ); // Render loading screen
  }

  return (
    <div className="currentContent margintop50">
      <div className="banner-top">
        <div className="marginleft">
          <a href="https://www.annuityanswers.com">
            {" "}
            <img src="https://assets.website-files.com/6443797b2dbb5540710c396a/644f8eb3be86de7d6d73f235_logo.svg"></img>
          </a>
        </div>
        <div className="marginright">
          Ask an expert: <br />
          <strong>
            <a href="tel:1-800-922-1894" className="nostylelink">
              1-800-922-1894
            </a>
          </strong>
        </div>
      </div>

      <div className="disclaimer-banner">
        <div className="col1">
          <img
            height="30px"
            src="https://uploads-ssl.webflow.com/6443797b2dbb5540710c396a/65265d787be878338ae1b26c_5stars.svg"
          />
          <div>10,000+ Happy Clients</div>
        </div>
        <div className="col2">
          <a
            className="button-secondary small"
            href="https://www.annuityanswers.com/retirevana"
          >
            GET FREE ADVICE
          </a>
        </div>
      </div>
      <h2>YOUR RESULTS</h2>
      {total > 0 && (
        <p className="stubbed-p">
          Your target return is: <strong>{total}%</strong>
        </p>
      )}
      {total > 0 && parseFloat(total) < 7.52 && (
        <p className="stubbed-p">Congratulations! You may be able to retire.</p>
      )}
      <p className="stubbed-p">
        We had our AI backtesting machine hypothetically 'invest'{" "}
        <strong>${formatCurrency(investment)} </strong>into the most popular
        contracts in America daily for the last decade. <br /> <br />
        <strong>
          You are seeing the best, worst, and average returns out of all 3500+
          potential purchase dates.
        </strong>
      </p>
      <p className="stubbed-p">
        The highest return we found was: <strong>12.48%</strong>, the same
        product averaged 7.52% anually
      </p>
      {/* <p className="stubbed-p">*It may be smart to consider allocation methods with an <strong>average</strong> return exceeding what you predict inflation will be at during your retirement. Inflation is currently around 5% but has been at an average of 2% over the last 20 years.</p> --> */}
      <div className="results-container">
        <div className="slider-container">
          <Splide
            options={{
              width: "100%",
              perPage: 5,
              arrows: true,
              gap: "5px",
              breakpoints: {
                1240: {
                  perPage: 3,
                  gap: "10px",
                },
                876: {
                  perPage: 2,
                  fixedWidth: "45%",
                },
                670: {
                  perPage: 1,
                  fixedWidth: "90%",
                },
              },
            }}
            aria-label="Backtester results"
          >
            {slides}
          </Splide>
        </div>
      </div>
      <p className="stubbed-p disclaimer wacky-margin">
        <strong>DISCLAIMER:</strong>
        <br /> Past performance is no guarantee of future results. Be aware that
        we're not testing annuities themself but the 'motor' or 'allocation
        method' that powers them. We find value in allocation method backtesting
        as one of several factors in your annuity selection. Avoid making your
        decision solely based on these results.
      </p>

      <h4 className="subbed-h4">Making sense of these results</h4>
      <VimeoPlayer videoUrl="https://vimeo.com/851508720?api=1" />
      <p className="stubbed-p">
        These results are from our basic, generic backtester. If you would like
        to see the product names, or to run a more comprehensive backtest
        tailored to your own specification, on contracts of your choice; <br />
        <br />
        <strong>
          Do not hesitate to schedule your free consultation by{" "}
          <a href="https://www.annuityanswers.com/retirevana">clicking here</a>
        </strong>
      </p>
      <a
        href="https://www.annuityanswers.com/retirevana"
        className="button-primary"
      >
        CONTACT US FOR THE PRODUCT NAMES & FULL REPORT
      </a>
      <p className="stubbed-p">
        <br /> <br />
        We do sell annuities but we never charge you for this service. <br />
        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.annuityanswers.com/about-us#how-do-we-get-paid"
        >
          Click here to learn how we make money
        </a>
      </p>
    </div>
  );
};

export default Step5;
