import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import WelcomeScreen from "./components/WelcomeScreen";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import Step4 from "./components/Step4";
import Step5 from "./components/Step5";
import HeaderTop from "./components/HeaderTop";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stepNumber: 1,
      input1: "",
      input2: "",
      input3: "",
      total: 0,
      investment: 0,
    };
  }

  handleTotalChange = (total) => {
    this.setState({ total });
  };

  handleInvestmentChange = (investment) => {
    this.setState({ investment });
  };

  handleStepChange = (stepNumber) => {
    this.setState({ stepNumber });
  };

  render() {
    const { total, investment } = this.state;

    return (
      <div className="App">
        <Routes>
          <Route
            exact
            path="/"
            element={
              <>
                <HeaderTop stepNumber={1} />
                <WelcomeScreen handleStepChange={this.handleStepChange} />
              </>
            }
          />
          <Route
            exact
            path="/how-much-you-need"
            element={
              <>
                <HeaderTop stepNumber={2} />
                <Step2 handleStepChange={this.handleStepChange} />
              </>
            }
          />
          <Route
            exact
            path="/calculate-your-rate"
            element={
              <>
                <HeaderTop stepNumber={3} />
                <Step3
                  handleStepChange={this.handleStepChange}
                  handleTotalChange={this.handleTotalChange}
                  handleInvestmentChange={this.handleInvestmentChange}
                  total={total}
                  investment={investment}
                />
              </>
            }
          />
          <Route
            exact
            path="/enter-your-rate"
            element={
              <>
                <HeaderTop stepNumber={3} />
                <Step4
                  handleStepChange={this.handleStepChange}
                  handleTotalChange={this.handleTotalChange}
                  handleInvestmentChange={this.handleInvestmentChange}
                  total={total}
                  investment={investment}
                />
              </>
            }
          />
          <Route
            exact
            path="/results"
            element={
              <>
                <Step5 total={total} investment={investment} />
              </>
            }
          />
        </Routes>
      </div>
    );
  }
}

export default App;
