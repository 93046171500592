import React from "react";
import "../styles.css"; // import external stylesheet

function HeaderTop(props) {
  const circles = [];

  for (let i = 1; i <= 4; i++) {
    circles.push(
      <div
        key={i} // added unique key prop
        className={`circle ${props.stepNumber >= i ? "active" : ""}`}
      />
    );
  }

  return (
    <div className="header">
      <div className="margin20">
        <a href="https://www.annuityanswers.com/">
      <img alt="decorative" src="https://uploads-ssl.webflow.com/6443797b2dbb5540710c396a/644f8eb3be86de7d6d73f235_logo.svg"></img>
      </a>
      </div>
      <div className="circles-container">
        {circles.map((circle, index) => (
          <React.Fragment key={`circle-${index}`}>
            {index > 0 && (
              <hr
                key={`line-${index}`}
                className={`line ${
                  props.stepNumber > index ? "active" : ""
                }`}
              />
            )}
            {circle}
          </React.Fragment>
        ))}
      </div>
      <h1>What can you expect from an annuity?</h1>
      <p>Find historical data in 30 seconds</p>

      <div className="custom-shape-divider-bottom-1685016464">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z" className="shape-fill"></path>
    </svg>
</div>
    </div>
  );
}

export default HeaderTop;
