import React, { Component } from "react";
import ActionButton from "./ActionButton";
import CurrencyInput from "./CurrencyInput";

class Step3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      number1: "",
      number2: "",
      number3: "",
      totaltemp: 0,
      investment: 0
    };
  }

  handleStepChange = (desiredStepChange) =>{
    const {totaltemp, investment} = this.state;
    this.props.handleTotalChange(totaltemp);
    this.props.handleInvestmentChange(investment);
    this.props.handleStepChange(desiredStepChange);
      };

  componentDidMount() {
    const { number1, number2, number3 } = this.state;
    this.calculateTotal(number1, number2, number3);
  }

  componentDidUpdate(prevProps, prevState) {
    const { number1, number2, number3 } = this.state;
    if (number1 !== prevState.number1 || number2 !== prevState.number2 || number3 !== prevState.number3) {
      this.calculateTotal(number1, number2, number3);
    }
  }

  calculateTotal = (number1, number2, number3) => {
    if (number1 && number2 && number3) {
      const invested = parseInt(number1); //1000000 need 2.4%
      const income = parseInt(number2);//2000
      const outgoing = parseInt(number3);//4000
      const info = (outgoing - income) * 12; //24000
      const finalsumbeforeinterest = info/invested * 100;
      const finalsum = info / invested * 100 + 5.0;
      this.setState({ totaltemp: finalsum.toFixed(2) });
      this.setState({ investment: parseInt(invested) });
    } else {
      this.setState({ totaltemp: 0 });
    }
  }

  render() {
    const { number1, number2, number3, totaltemp } = this.state;
    return (
      <div className="currentContent">
        <div className="introTextContainer">
          <h2 className="introHeading"><br /><span className="boldcaps smaller">Calculate your target interest rate</span></h2>
          <p className="stubbed-p">Please complete the form below to calculate your target interest rate. <br />Your information will <strong>NOT</strong> be saved anywhere. We guarantee 100% privacy</p>
          <div className="inputs-container">
            <CurrencyInput placeholder={"Enter an amount e.g. 800,000"} toolTipText={"Assuming you have set aside comfortable reserves."} inputMax={11} isPercentage={false} value={number1} setValue={value => this.setState({ number1: value })} label="Initial investment amount" id="investment" />
            <CurrencyInput placeholder={"Enter an amount e.g. 4,000"} toolTipText={"<strong>Example</strong><br />Spouse 1 social security: $2300<br />Spouse 2 social security: $1734, <br />Spouse 1 pension: $1300<br />Total: $5334.00"} inputMax={11} value={number2} setValue={value => this.setState({ number2: value })} label="Monthly retirement income after tax" id="retirementincome"/>
            <CurrencyInput placeholder={"Enter an ammount e.g. 4,800"} toolTipText={"Amount to perpetuate your standard<br />of living after retirement, be sure to <br />consider health insurance costs"} inputMax={11} value={number3} setValue={value => this.setState({ number3: value })} label="Monthly outgoings (bills)" id="outgoings" />
          </div>
        </div>
        <div className="display-results">
          <p className="stubbed-p">You would need an annuity with an annual return exceeding:</p>

          <div className="total-display">{(totaltemp) ? <span>{totaltemp}%</span> : <span className="small-text"></span> }</div>
          <p>To <i>potentially</i> retire. <br />(Assumed inflation rate: 5%)</p>

          {(number1 !== "" && number2 !== "" && number3 !== "") && <div className="button-container">
          <ActionButton
            handleStepChange={this.handleStepChange}
            desiredStepChange={5}
            btnText={`SEE ANNUITY OPTIONS FOR YOU AND THEIR PREVIOUS PERFORMANCE`}
            btnStyle={"button-primary"}
          />
          </div>}
        </div>
      </div>
    );
  }
}

export default Step3;
