import React, { Component } from "react";
import ActionButton from "./ActionButton";
import PercentageInput from "./PercentageInput";
import CurrencyInput from "./CurrencyInput";

class Step4 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totaltemp: 0,
      investment: 0
    };
  }

  doNext = () => {
    const {totaltemp, investment} = this.state;
    this.props.handleTotalChange(totaltemp)
    this.props.handleInvestmentChange(parseInt(investment))
    this.props.handleStepChange(5)
  }
  
  handleStepChange = (desiredStepChange) =>{
    const {totaltemp, investment} = this.state;
    this.props.handleTotalChange(totaltemp);
    this.props.handleInvestmentChange(parseInt(investment));
    this.props.handleStepChange(desiredStepChange);
      };

  render() {
    const { totaltemp, investment } = this.state;
    return (
      <div className="currentContent">
        <div className="introTextContainer">
          <h2 className="introHeading"><br /><span className="boldcaps smaller">Enter your target interest rate</span></h2>
          <div className="inputs-container">
          <PercentageInput inputMax={5} value={totaltemp.toString()} setValue={value => this.setState({ totaltemp: value })} label="Desired rate" id="target" />
          <CurrencyInput inputMax={10} value={investment.toString()} setValue={value => this.setState({ investment: value })} label="Investment amount" id="target2" />

          </div>
        </div>
        <div className="display-results">
  {/^(?:(?:100(?:\.0{1,2})?)|(?:[1-9]?\d(?:\.\d{1,2})?))%?$/.test(totaltemp) && totaltemp !== "0" && totaltemp !== 0 && !/^0(\.0{1,2})?$/.test(totaltemp) && (investment > 9999) && (
    <div className="button-container">
      <ActionButton
        handleStepChange={this.handleStepChange}
        desiredStepChange={5}
        btnText={`SEE ANNUITY OPTIONS FOR YOU AND THEIR PREVIOUS PERFORMANCE`}
        btnStyle={"button-primary"}
      />
    </div>
  )}
</div>



      </div>
    );
  }
}

export default Step4;
