import React, { Component } from "react";
import ActionButton from "./ActionButton";

class WelcomeScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
    };
  }
  handleStepChange = (desiredStepChange) =>{
this.props.handleStepChange(desiredStepChange);
  };
  handleNextPage = () => {
    this.setState({ currentPage: this.state.currentPage + 1 });
  };

  handlePreviousPage = () => {
    this.setState({ currentPage: this.state.currentPage - 1 });
  };

  render() {
    return (
      <div className="currentContent">
        {this.renderPage()}
      </div>
    );
  }

  renderPage() {
    switch (this.state.currentPage) {
      case 1:
        return (
          <div className="introTextContainer">
            <h2 className="introHeading">Welcome to the <br /><span className="boldcaps">retirement annuity calculator</span></h2>
            <p className="stubbed-p">If you’d like to reasonably be able to retire, you would need to provide the money needed to pay your bills above your Social Security or other retirement income. In addition to keeping up with inflation, you will also want to have adequate reserves for unexpected
Expenses. <br /><br />Otherwise, you may start on a downward trajectory the minute you retire.</p>
            <div className="button-container">
            <button className="button-primary" onClick={this.handleNextPage}>Next</button>
          </div></div>
        );
      case 2:
        return (
          <div className="introTextContainer">
            <h2 className="introHeading">Welcome to the <br /><span className="boldcaps">retirement annuity calculator</span></h2>
            <p className="stubbed-p">The hard part is that there are so many different things you can do with your money to try and get returns high enough for you to comfortably retire.<br /><br />Only you can decide what you think is reasonable to expect from safer options like annuities, but how do you get accurate information regarding how different internal calculation methods used by all of the popular annuities would have performed in the past in real market circumstances?</p>
            <div className="button-container">
            <button className="button-primary" onClick={this.handleNextPage}>Next</button>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="introTextContainer">
            <h2 className="introHeading">Welcome to the <br /><span className="boldcaps">retirement annuity calculator</span></h2>
            <p className="stubbed-p">Using artificial intelligence, we test drive the actual performance of the actual contractual interest calculation method of any annuity we are considering in our family portfolio.</p>
            <div className="button-container">
            <button className="button-primary" onClick={this.handleNextPage}>Next</button>
          </div>
          </div>
        );
        case 4:
          return (
            <div className="introTextContainer">
              <h2 className="introHeading">Welcome to the <br /><span className="boldcaps">retirement annuity calculator</span></h2>
              <p className="stubbed-p">What would the average returns look like if you bought one every day for 10 years? It's not practical to do that in real life but it's practical with a artificial intelligence.</p>
              <div className="button-container">
              <ActionButton handleStepChange={this.handleStepChange} desiredStepChange={2} btnText="Next" btnStyle={"button-primary"}></ActionButton>
              </div>
            </div>
          );
      default:
        return null;
    }
  }
}

export default WelcomeScreen;
