import React, { useState } from 'react';
import ReactPlayer from 'react-player';

const VimeoPlayer = ( {videoUrl} ) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayButtonClick = () => {
    let btn = document.querySelector(".vidOverlay");
    btn.classList.add("hide");
    setIsPlaying(true);
  };

  return (
    <div className='player-wrapper'>
      <div className='vidOverlay' onClick={handlePlayButtonClick}>
      <button className="playbtn" onClick={handlePlayButtonClick}></button>
      </div>
      
      <ReactPlayer
        className="react-player"
        playsinline
        url={videoUrl}
        width="100%"
        height="100%"
        controls={true}
        playing={isPlaying} // Use the state variable here
        allow="autoplay; fullscreen; picture-in-picture"
      />
    </div>
  );
};

export default VimeoPlayer;
