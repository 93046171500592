import React, { Component } from "react";
import ActionButton from "./ActionButton";

class Step2 extends Component {

    handleStepChange = (desiredStepChange) =>{
        this.props.handleStepChange(desiredStepChange);
          };

        render() {
        return (
        <div className="currentContent">

                <h2 className="introHeading">First off <br /><span className="boldcaps smaller">Let's figure out how much you would need</span></h2>
                <p className="stubbed-p">If you invested your savings into an annuity, what interest rate would you need to retire?</p>
                <div className="button-container">
                    <ActionButton handleStepChange={this.handleStepChange} desiredStepChange={3} btnText="CALCULATE THE RATE I NEED" btnStyle={"button-primary"}></ActionButton>
                    <ActionButton handleStepChange={this.handleStepChange} desiredStepChange={4} btnText="I ALREADY HAVE A NUMBER IN MIND" btnStyle={"button-secondary"}></ActionButton>
                </div>
            </div>
        );
        }
}

export default Step2;