import { useState, useEffect } from 'react';
import { Tooltip } from 'react-tooltip';

const CurrencyInput = ({ placeholder, toolTipText, value, setValue, label, id, inputMax }) => {
  const [formattedValue, setFormattedValue] = useState('');

  useEffect(() => {
    const formatted = formatValue(value);
    setFormattedValue(formatted);
  }, [value]);

  const formatValue = (value) => {
    if (value === '') {
      return '';
    }
    const numeric = parseInt(value.replace(/[^0-9]/g, ''), 10);
    if (isNaN(numeric)) {
      return value;
    }
    return numeric.toLocaleString();
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    const sanitizedValue = newValue.replace(/[^0-9]/g, '');
    const formatted = formatValue(sanitizedValue);
    setFormattedValue(formatted);
    setValue(sanitizedValue);
  };

  return (
    <div className="inputGroup">
      <div className="label-container">
        <label className="form-label" htmlFor={id}>
          {label}
        </label>
        {toolTipText && (
          <img
            alt=""
            data-tooltip-id="my-tooltip"
            data-tooltip-html={toolTipText}
            className="tooltip-indicator"
            src="https://uploads-ssl.webflow.com/6443797b2dbb5540710c396a/6475d08ea6288220f6b44261_tooltip.svg"
          ></img>
        )}
        <Tooltip id="my-tooltip" />
      </div>
      <div className="input-wrapper">
        <span className="currencyUSD">$</span>
        <input
        inputMode='decimal'
          name={id}
          id={id}
          type="text"
          value={formattedValue}
          onChange={handleChange}
          maxLength={inputMax}
          className="number-input currencyinput"
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default CurrencyInput;
