import React from 'react';
import VideoModal from './VideoModal';

const Result = (props) => {

  const markup = (text) => {

    return {__html: text}
  }

 //pass in the result of return per dollar invested e.g. 203,503/100,000 = 2.03503
 const calculateAmountPerDollar = (perDollar) => {
    let amount = props.investment;

    amount = amount * perDollar;

    return Math.round(amount);
  }


  const calculateCompoundInterest = (rate) => {
    let amount = props.investment;
  
    for (let i = 1; i <= 10; i++) {
      amount += amount * (rate / 100);
    }
  
    return Math.round(amount);
  }

  const formatCurrency = (number) => {
    // Convert the number to a string
    let numStr = String(number);
  
    // Split the string into whole number and decimal parts
    let parts = numStr.split(".");
    let wholeNumber = parts[0];
  
    // Insert commas every three digits in the whole number part
    let formattedNumber = "";
    for (let i = wholeNumber.length - 1, j = 1; i >= 0; i--, j++) {
      formattedNumber = wholeNumber.charAt(i) + formattedNumber;
      if (j % 3 === 0 && i > 0) {
        formattedNumber = "," + formattedNumber;
      }
    }
  
    return formattedNumber;
  }

  return (
    <div className={`results-column ${props.isAverill ? 'averill' : ''}`}>
      <span className="product-title">
        <strong>{props.productTitle}</strong><br /><br />
      </span>
      <p className="product-name" dangerouslySetInnerHTML={markup(props.productDisplayName)}></p>
      <div className="results-table">
        <table>
          <tbody>
            
          <tr>
              <td className="celltitle" colSpan={2}>Average value after 10 years</td>
              </tr>
            <tr>
            <td className={`final-value ${props.isAverill ? 'bold goldtext' : ''}`} colSpan={2}>
                ${formatCurrency(calculateAmountPerDollar(props.dollarAmountDecimal))}
                
              </td>
            </tr>
            <tr>
            <td className="celltitle">Best date</td>
              <td className="celltitle">Annual rate</td>
              </tr>
            <tr>
              <td className="date-column">{props.bestDate}</td>
              <td className="percentage-column">{props.bestRate}%</td>
            </tr>
            <tr>
            <td className="celltitle">Average date</td>
              <td className="celltitle">Annual rate</td>
              </tr>
            <tr>
              <td className="date-column">{props.averageDate}</td>
              <td className="percentage-column highlighted">{props.averageRate}%</td>
            </tr>
            <tr>
              <td className="celltitle">Worst date</td>
              <td className="celltitle">Annual rate</td>
              </tr>
            <tr>
              <td className="date-column">{props.worstDate}</td>
              <td className="percentage-column">{props.worstRate}%</td>
            </tr>
          </tbody>
        </table>
      </div>
      <VideoModal buttonText={"Why do the rates vary?"} targetElementId="currentcontent" videoUrl="https://vimeo.com/851508689?api=1" />
    </div>
  );
};

export default Result;
