import React from "react";
import { useNavigate } from "react-router-dom";

function ActionButton({ desiredStepChange, btnText, handleStepChange, btnStyle}) {
  const navigate = useNavigate();
  const pages = ["/", "/how-much-you-need", "/calculate-your-rate", "/enter-your-rate", "/results"];

  const handleClick = () =>{
        goToStep();
  }

  const goToStep = () => {
    handleStepChange(desiredStepChange);
    navigate(pages[desiredStepChange-1], {replace:true});
  };

  return (
    <button className={btnStyle} onClick={handleClick}>
      {btnText}
    </button>
  );
}

export default ActionButton;
